import React, { useEffect } from "react";
import "./timeline.css";

function Timeline() {
  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in");
          } else {
            entry.target.classList.remove("fade-in");
          }
        });
      },
      { threshold: 0.1 }
    );

    const items = document.querySelectorAll(".content-item");
    items.forEach((item) => {
      observer.observe(item);
    });

    return () => {
      items.forEach((item) => {
        observer.unobserve(item);
      });
    };
  }, []);

  return (
    <div className="timeline-container container">
      <div className="border border-success p-3">
        <h2 className="text-center">Project Timeline</h2>

        <div className="timeline row justify-content-center align-items-center">
          <div
            className="timeline-step col-6 col-md-2 text-center"
            onClick={() => handleScrollTo("discovery")}
          >
            <span>Discovery</span>
          </div>
          <div className="col-1 col-md-1 text-center">→</div>
          <div
            className="timeline-step col-6 col-md-2 text-center"
            onClick={() => handleScrollTo("design")}
          >
            <span>Design</span>
          </div>
          <div className="col-1 col-md-1 text-center">→</div>
          <div
            className="timeline-step col-6 col-md-2 text-center"
            onClick={() => handleScrollTo("prototypes")}
          >
            <span>Prototypes</span>
          </div>
          <div className="col-1 col-md-1 text-center">→</div>
          <div
            className="timeline-step col-6 col-md-2 text-center"
            onClick={() => handleScrollTo("build")}
          >
            <span>Build</span>
          </div>
          <div className="col-1 col-md-1 text-center">→</div>
          <div
            className="timeline-step col-6 col-md-2 text-center"
            onClick={() => handleScrollTo("more-user-testing")}
          >
            <span>User Testing</span>
          </div>
          <div className="col-1 col-md-1 text-center">→</div>
          <div
            className="timeline-step col-6 col-md-2 text-center"
            onClick={() => handleScrollTo("launch")}
          >
            <span>Launch</span>
          </div>
        </div>
      </div>
      <div className="timeline-content">
        <div className="content-item" id="discovery">
          <h3>Discovery</h3>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/assets/projects/juz-delights-design.png"
              alt="Juz Delights Research image"
              style={{ maxWidth: "50%" }}
            />
            <p
              className="regular-text"
              style={{
                maxWidth: "50%",
                fontSize: "20px",
              }}
            >
              During the Discovery phase, we dived deep into understanding the
              needs and goals of Juz Delights. This involved going through
              existing copywriting and research, including market analysis and
              competitor benchmarking.
            </p>
          </div>
        </div>
        <br />
        <br />
        <div className="content-item" id="design">
          <h3>Design</h3>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              className="regular-text"
              style={{
                maxWidth: "45%",
                fontSize: "20px",
              }}
            >
              Our main goal was to ensure a smooth customer journey while
              keeping the site as simple as possible. Our client does not have
              the bandwidth to maintain the website, so we want to ensure that
              it is easy for them to make changes to and truly fits their needs.
            </p>
            <img
              src="/assets/projects/juz-delights-sitemap.png"
              alt="Juz Delights Research image"
              style={{ maxWidth: "55%" }}
            />
          </div>
        </div>
        <br />
        <br />
        <div className="content-item" id="prototypes">
          <h3>Prototypes</h3>
          <div className="row">
            <div className="col">
              <img
                src="/assets/projects/juz-delights-wireframes.png"
                alt="Juz Delights Wireframe"
                className="banner-image"
              />
            </div>
            <div className="col">
              <img
                src="/assets/projects/juz-delights-midfi.png"
                alt="Juz Delights Wireframe"
                className="banner-image"
              />
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "stretch", // Ensure columns stretch to equal height
            }}
          >
            <div
              className="col-md-4"
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                src="/assets/projects/jd-ipad1.png"
                alt="Juz Delights ipadimg1"
                className="ipadImg"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  marginBottom: "1vw",
                }}
              />
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <h4 style={{ fontSize: "1.5vw", margin: "1vw 0" }}>
                  User Friendly Product Ordering
                </h4>
                <p style={{ margin: "0" }}>
                  Product pages are very simple with clear call-to-actions and
                  multiple ways to share information with others.
                </p>
              </div>
            </div>

            <div
              className="col-md-4"
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                src="/assets/projects/jd-ipad2.png"
                alt="Juz Delights ipadimg2"
                className="ipadImg"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  marginBottom: "1vw",
                }}
              />
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <h4 style={{ fontSize: "1.5vw", margin: "1vw 0" }}>
                  Clear FAQ and Location Information
                </h4>
                <p style={{ margin: "0" }}>
                  Organized location and FAQ sections that reduce friction in
                  accessing information and reduce support requests.
                </p>
              </div>
            </div>

            <div
              className="col-md-4"
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                src="/assets/projects/jd-ipad3.png"
                alt="Juz Delights ipadimg3"
                className="ipadImg"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  marginBottom: "1vw",
                }}
              />
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <h4 style={{ fontSize: "1.5vw", margin: "1vw 0" }}>
                  Client's Purpose
                </h4>
                <p style={{ margin: "12" }}>
                  We made sure to authentically narrate the client's goals and
                  ensure all of our design decisions aligned with the client's
                  core mission.
                </p>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />
        <div className="content-item" id="build">
          <h3>Build</h3>

          <div className="row">
            <p
              className="regular-text"
              style={{
                maxWidth: "65%",
                fontSize: "20px",
              }}
            >
              Our website was built quickly using Ecwid.
              We customized it to fit our design and did a final review for any
              last-minute changes or adjustments.
            </p>
          </div>
        </div>
        <br />
        <br />
        <div className="content-item" id="more-user-testing">
          <h3>User Testing</h3>

          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              className="col"
              style={{
                maxWidth: "60%",
                fontSize: "20px",
                marginRight: "2wv",
              }}
            >
              <p
                className="regular-text"
                style={{
                  fontSize: "16px",
                }}
              >
                After preparing a script covering our general website design and
                our client's concerns. We conducted user interviews with
                multiple individuals through zoom. By watching them walk through
                the website, we were able to identify confusing features and
                revise.
              </p>
              <p
                className="regular-text "
                style={{
                  fontSize: "16px",
                }}
              >
                Through the re:bloom program, we were also able to complete a
                critique session with multiple volunteers.
              </p>
            </div>

            <div className="col">
              <img
                src="/assets/projects/juz-delights-roundtable.png"
                alt="Juz Delights Research image"
                style={{ maxWidth: "80%" }}
                className="col"
              />
            </div>
          </div>
        </div>
        <div className="content-item" id="launch">
          <h3>Launch</h3>
          <p>Coming soon</p>
        </div>
      </div>
    </div>
  );
}

export default Timeline;
