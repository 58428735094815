import "../projects.css";
import Timeline from "../timeline/armoireTimeline"; // Updated timeline import
import "bootstrap/dist/css/bootstrap.min.css";

function Armoire() {
  return (
    <div className="project-container">
      {/* <h1>Armoire</h1> */}
      {/* <img src="/assets/projects/armoire.png" alt="Armoire Project" /> */}

      <div className="banner">
        <img
          src="assets/projects/armoire/armoire-banner.png" // Updated image source
          alt="Armoire Project Image"
          className="banner-image"
        />
      </div>

      <div className="content-section">
        <h1 style={{ textAlign: "start", fontSize: "50px" }}>Armoire</h1>
        <br />
        <div className="intro">
          <div className="col" style={{ marginRight: "15px" }}>
            <h2>Overview</h2>
            <h4 style={{ maxWidth: "90%", lineHeight: "1.5" }}>
              Armoire is a fashion app focused on helping users organize their
              closet and plan outfits. It’s designed for those who want to
              streamline their daily routines and get curated style prompts for
              easy outfit selection.
            </h4>

            <div className="badge-container">
              <span className="badge">UI/UX</span>
              <span className="badge">Prototyping</span>
              <span className="badge">User Research</span>
              <span className="badge">Figma</span>
            </div>
          </div>
          <div className="col">
            <h2>Quick Summary</h2>
            <h4
              style={{ maxWidth: "95%", lineHeight: "1.5", fontSize: "20px" }}
            >
              • This project involved translating the{" "}
        
                physical interface
              of a closet into a digital UI. The goal was to replicate the
              process of{" "}
                organizing
              and{" "}
                selecting outfits
              while adhering to natural mapping and conceptual models.
            </h4>
            <h4
              style={{ maxWidth: "95%", lineHeight: "1.5", fontSize: "20px" }}
            >
              • I selected{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>
                Pinterest
              </span>{" "}
              as the base app for this digital translation due to its{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>
                simplicity
              </span>{" "}
              and{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>
                visual-centric layout
              </span>
              . This approach ensures the interface remains{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>
                minimalistic
              </span>{" "}
              and avoids over-complication.
            </h4>
            {/* <h4
              style={{ maxWidth: "95%", lineHeight: "1.5", fontSize: "20px" }}
            >
              • The project focused on defining the core{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>
                interaction behaviors
              </span>{" "}
              users expect when interacting with a closet, including{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>curation</span>{" "}
              and{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>
                identity expression
              </span>
              . The digital interface aimed to match these behaviors in a
              streamlined way.
            </h4>
            <h4
              style={{ maxWidth: "95%", lineHeight: "1.5", fontSize: "20px" }}
            >
              • The app's design process involved{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>
                prototyping
              </span>{" "}
              and iterating on feedback, ensuring the digital version of the
              closet felt intuitive and aligned with user expectations for{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>feedback</span>{" "}
              and{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>
                constraints
              </span>
              .
            </h4> */}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="content-section">
        <Timeline />
        <p></p>
      </div>
      
    </div>
  );
}
export default Armoire;
