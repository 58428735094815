import React from "react";
import "./footer.css"; // Make sure to link your CSS file
import { LinkedinLogo, Envelope } from "@phosphor-icons/react";

const Footer = () => {
  return (
    <div className="footer-page">
      <div className="footer-rectangle">
        <div className="footer-content">
          <p className="footer-text">Made with ❤️ and React</p>
        </div>
        <div className="footer-icons">
          <a
           href="mailto:ma.ra@northeastern.edu"
            className="footer-icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Envelope size={40} color="#1A1A1A" />
          </a>
          <a
            href="https://www.linkedin.com/in/rachel-ma2"
            className="footer-icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedinLogo size={38} color="#1A1A1A" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
